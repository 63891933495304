/* eslint-disable functional/immutable-data */

import * as cnpj from '@fnando/cnpj';
import * as cpf from '@fnando/cpf';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';

import helpers from './helpers';
import t from './translations';

const phoneUtil = PhoneNumberUtil.getInstance();

// Ref: https://pt.stackoverflow.com/questions/188190/formatar-moeda-com-separador-de-milhar
export const formatMoneyPT = function (value: any): string {
  if (helpers.isNil(value)) {
    return null;
  }

  const number = (value / 100).toFixed(2).split('.');
  number[0] = number[0].split(/(?=(?:...)*$)/).join('.');
  return number.join(',');
};

export const toCurrencyPT = (value: any): any => formatMoneyPT(value);

const formatPhone = (value: string | null | undefined): string => {
  if (!value) {
    return '';
  }

  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value, 'BR');
  return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
};

const documentMask = (document: string): string => {
  if (helpers.isNil(document)) {
    return document;
  }

  if (cpf.isValid(document)) {
    return cpf.format(document);
  }

  if (cnpj.isValid(document)) {
    return cnpj.format(document);
  }

  return document;
};

const emailMask = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const cellPhoneMask = /(\(\d{2,}\))*(\s)*\d{4,}-\d{4}/g;

const zipCodeBRMask = /[0-9]{5}-[\d]{3}/g;

const maskAtrr = (key: string, value: any, options?: { readonly format?: string }): string => {
  if (key === 'dateOfBirth') {
    return moment(value).format(options?.format || 'l');
  }

  if (key === 'income') {
    return `R$ ${toCurrencyPT(value * 100)}`;
  }

  if (key === 'limit') {
    return `R$ ${toCurrencyPT(value)}`;
  }

  if (key === 'document' && cnpj.isValid(value)) {
    return cnpj.format(value);
  }

  if (key === 'document' && cpf.isValid(value)) {
    return cpf.format(value);
  }

  if (key === 'fee') {
    return value;
  }

  if (key === 'approved') {
    return value ? 'Aprovado' : 'Bloqueado';
  }

  if (key === 'phone') {
    const number = phoneUtil.parseAndKeepRawInput(value, 'BR');
    return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
  }

  if (key === 'createdAt') {
    return new Date(value).toLocaleString('default', {
      month: 'short',
      year: '2-digit',
    });
  }

  // TODO: Remove from here
  if (key === 'guarantor') {
    return value.name;
  }

  // if (key === 'gender') {
  //   return t[value];
  // }

  return value;
};

export default {
  formatMoneyPT,
  toCurrencyPT,
  emailMask,
  cellPhoneMask,
  zipCodeBRMask,
  maskAtrr,
  documentMask,
  formatPhone,
  phoneUtil,
};
