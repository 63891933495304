/* eslint-disable no-unused-expressions */
/* eslint-disable functional/immutable-data */

import { strip } from '@fnando/cnpj';
import { ApolloError } from 'apollo-boost';
import { GraphQLError } from 'graphql';
import moment from 'moment';

import { Farmer, Guarantor, Partner } from '../generated/types';
import masks from './masks';
import t from './translations';

const parseError = (error: ApolloError | undefined): ApolloError | undefined => {
  if (!error) {
    return undefined;
  }

  const { graphQLErrors, ...apolloError } = error;

  // eslint-disable-next-line functional/prefer-readonly-type
  const newGraphQLErrors: GraphQLError[] = [];
  graphQLErrors?.map((graphQLError) => {
    const { message, ...newGraphqlError } = graphQLError;
    if (typeof graphQLError.message === 'string') {
      return newGraphQLErrors.push({
        message: message.length >= 255 ? t.error.ERROR : message,
        ...newGraphqlError,
      });
    }

    if (typeof graphQLError.message === 'object') {
      const nestError = message as any;

      if (typeof nestError.message === 'string') {
        return newGraphQLErrors.push({
          message: nestError.message,
          ...newGraphqlError,
        });
      }

      if (typeof nestError.message === 'undefined' && typeof nestError.error === 'string') {
        return newGraphQLErrors.push({
          message: nestError.error,
          ...newGraphqlError,
        });
      }

      return nestError?.message?.map((nestErrorMessage: any) => {
        Object.keys(nestErrorMessage.constraints).map((key) => {
          newGraphQLErrors.push({
            message: nestErrorMessage.constraints[key],
            ...newGraphqlError,
          });
        });
      });
    }
  });

  return {
    graphQLErrors: newGraphQLErrors,
    ...apolloError,
  };
};

const handleError = (error: any): readonly Error[] => {
  return error?.graphQLErrors?.map((gqError: any) => ({
    statusCode: gqError.message.statusCode,
    error: gqError.message,
  }));
};

const isNil = (value: any): boolean => value === null || value === undefined || isNaN(value);
const isArr = (arr: any): boolean => arr !== null && Array.isArray(arr) === true;
const isObj = (obj: any): boolean =>
  obj !== null && typeof obj === 'object' && Array.isArray(obj) === false;
const isStr = (str: string): boolean => str !== null && typeof str === 'string';
const isEmpty = (attr: any): boolean => {
  if (isStr(attr)) {
    return attr.length === 0;
  } else if (isArr(attr) || isStr(attr)) {
    return attr.length === 0;
  } else if (isObj(attr)) {
    return Object.keys(attr).length === 0;
  } else {
    return false;
  }
};

const filterByName = (arr: any, query: string): any => {
  const value = query.toLowerCase();

  return arr?.filter(
    (item: any) =>
      item?.name?.toLowerCase()?.includes(value) ||
      item?.firstName?.toLowerCase()?.includes(value) ||
      item?.lastName?.toLowerCase()?.includes(value) ||
      item?.user.document?.slice(0, 4).includes(value)
  );
};

const COUNTRY_CODE = '+55';
const DDD = '11';
const PHONE_NUMBER_LENGTH = 9;
const autoCompleteDDDPhone = (phone: string): string => {
  const number = strip(phone);
  if (number.length === PHONE_NUMBER_LENGTH) {
    return `${COUNTRY_CODE}${DDD}${number}`;
  }
  return `${COUNTRY_CODE}${number}`;
};

const flattenObject = (obj: any, prefix = ''): object =>
  Object.keys(obj).reduce((acc: any, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (obj[k] && typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

const ACTIONS_TYPE = {
  APPROVE: 'APPROVE',
  DELETE: 'DELETE',
  CHANGE_CREDIT_LIMIT: 'CHANGE_CREDIT_LIMIT',
  EDIT: 'EDIT',
};

const USER_TYPES = {
  FARMERS: 'farmers',
  GUARANTORS: 'guarantors',
  PARTNERS: 'partners',
  ADMIN: 'ADMIN',
};

const formatToKeyTranslation = (str: string): string => {
  if (str === 'street') {
    return 'ADDRESS';
  }
  if (str === 'cardNumber') {
    return 'CARD_NUMBER';
  }
  if (str === 'streetNumber') {
    return 'NUMBER';
  }
  if (str === 'registration') {
    return 'REGISTRATION_GUARANTOR';
  }
  if (str === 'bankAgency') {
    return 'AGENCY';
  }
  if (str === 'bankAccount') {
    return 'ACCOUNT';
  }
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toUpperCase();
};

const parseFarmerToObj = (farmer: Farmer): any => {
  const { user, dateOfBirth, ...others } = farmer;
  const formatedDate = masks.maskAtrr('dateOfBirth', moment.utc(dateOfBirth), {
    format: 'YYYY-MM-DD',
  });
  const obj = {
    dateOfBirth: formatedDate,
    ...others,
  };

  if (!user) {
    return obj;
  }

  const { address, ...fragmentUser } = user;

  return {
    ...obj,
    ...fragmentUser,
    ...address,
  };
};

const parsePartnerToObj = (partner: Partner): any => {
  const { category, user, bank, ...others } = partner;
  const obj = {
    ...others,
    categoryName: category?.name,
    bankId: bank?.id,
    bankName: bank?.name,
    bankAgency: bank?.agency,
    bankAccount: bank?.account,
  };

  if (!user) {
    return obj;
  }

  const { address, ...fragmentUser } = user;

  return {
    ...fragmentUser,
    ...obj,
    ...address,
  };
};

const parseGuarantorToObj = (guarantor: Guarantor): any => {
  const { user, ...others } = guarantor;

  if (!user) {
    return {
      ...others,
    };
  }

  const { address, ...fragmentUser } = user;
  return {
    ...fragmentUser,
    ...others,
    ...address,
  };
};

const parseCurrency = (amount: string): number => {
  if (amount.includes('.')) {
    const [value, cents] = amount.split('.');
    const twoCents = cents.slice(0, 2);
    const correctCents = twoCents.length === 1 ? `${twoCents}0` : twoCents;

    return Number(`${value}${correctCents}`);
  }

  if (amount.includes(',')) {
    const [value, cents] = amount.split(',');
    const twoCents = cents.slice(0, 2);
    const correctCents = twoCents.length === 1 ? `${twoCents}0` : twoCents;

    return Number(`${value}${correctCents}`);
  }

  return Number(amount) * 100;
};

const getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key];

const groupByMonth = (items: readonly any[], key: string, monthFormat: string): object =>
  items.reduce((result: any, item: any) => {
    const month = moment(item[key]).format(monthFormat);
    return {
      ...result,
      [month]: [...(result[month] || []), item],
    };
  }, {});

export default {
  handleError,
  parseError,
  isEmpty,
  isNil,
  isArr,
  isObj,
  isStr,
  filterByName,
  autoCompleteDDDPhone,
  ACTIONS_TYPE,
  USER_TYPES,
  formatToKeyTranslation,
  flattenObject,
  parseFarmerToObj,
  parsePartnerToObj,
  parseGuarantorToObj,
  parseCurrency,
  getKeyValue,
  groupByMonth,
};
